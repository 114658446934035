import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Subscription, interval } from 'rxjs';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, NavigationExtras, ActivatedRoute, Params } from '@angular/router';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExportgridComponent } from '../grid/exportgrid/exportgrid.component';
import { DeliveryboyaddressmapComponent } from '../deliveryboy/deliveryboyaddressmap/deliveryboyaddressmap.component';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {


  orderColl: any = [];
  statusColl: any = [];
  areaColl: any = []
  tempstatusColl: any = [];

  statusid: number = 0;
  totalordercount: number = 0;

  isadd: boolean = false;
  isedit: boolean = false;
  isdelete: boolean = false;
  isview: boolean = false;
  isprint: boolean = false;
  isexport: boolean = false;


  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Order";

  isorderplaced: boolean = false;
  isorderpacking: boolean = false;


  parafilterby: String = "ORDERPLACED";

  filterby: String = "ORDERPLACED";
  isselectlist: boolean = false;
  fromdate: any = null;
  todate: any = null;

  areaid: any = null

  orderplacedcount: number = 0;
  confirmcount: number = 0;
  outfordelivercount: number = 0;
  topackcount: number = 0;
  readytopickcount: number = 0;
  assigncount: number = 0;
  pickedcount: number = 0;
  deliverycount: number = 0;
  receivedcount: number = 0;
  cancelledcount: number = 0;
  allcount: number = 0;


  subscription: Subscription;
  intervalId: number;

  snewdate: any = new Date();

  searchtxt: any = "";
  columnDefs = [
    { headerName: 'Order', field: 'orderno' },
    { headerName: 'Order Date', field: 'orderdate', type: 'datetime', colcls: "tbtx" },
    { headerName: 'Customer', field: 'customername', colcls: "tbtx" },
    { headerName: 'Mobile', field: 'mobile' },
    { headerName: 'Items', field: 'productcount', type: 'decimal' },
    { headerName: 'Total', field: 'grandtotal', type: 'decimal' },
    { headerName: 'Order Status', field: 'orderstatus', colcls: "tbtx" },
    // { headerName: 'Area', field: 'areaname', sortable: true, filter: true, colcls: "tbtx" },
    // { headerName: 'Warehouse', field: 'warehousename', sortable: true, filter: true },
    { headerName: 'Paymode', field: 'paymode', colcls: "tbtx" },
    // { headerName: 'Paid Status', field: 'paidstatus', colcls: "tbtx" },
    { headerName: 'Delivery Slot', field: 'deliveryslot', colcls: "tbtx" },
    // { headerName: 'ExpectDate', field: 'expectdeliverydt', colcls: "tbtx" },
    { headerName: 'Confirmed', field: 'confirmbyname' },
    { headerName: 'ConfirmOn', field: 'confirmon', type: 'datetime', colcls: "tbtx" },
    { headerName: 'OutForDeliverBy', field: 'outfordeliveryname' },
    { headerName: 'OutForDeliverOn', field: 'outfordeliveryon', type: 'datetime', colcls: "tbtx" },
    { headerName: 'Delivered', field: 'deliveredbyname' },
    { headerName: 'DeliveredOn', field: 'deliveredon', type: 'datetime', colcls: "tbtx" },
  ]
  gridOptions = {
    enableactioncol: false,
    columnDefs: this.columnDefs,
    pageindex: 1
  };



  constructor(private appService: DhukanDataService, public router: Router, private route: ActivatedRoute,
    private confirmationDialogService: ConfirmationDialogService, private productFindService: NgbModal, private toastr: ToastrService,
    private loginService: LoginDataService, private datePipe: DatePipe,) {
    this.route.queryParams.subscribe(params => {
      if (params.filterstatus !== undefined) {
        this.parafilterby = params.filterstatus
        if (this.parafilterby != "") {
          this.filterby = this.parafilterby
        }
        if (params.areaid !=null) {
          this.areaid = Number(params.areaid)
        }
        
      }
    });


    this.fromdate = new Date();
    this.todate = new Date();

    this.fnServiceChanges();
  }

  //User Check
  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.fnUserFailedForm();
      }
    });
  }


  ngOnInit() {
    try {


      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    const source = interval(50000);
  //  this.subscription = source.subscribe(val => this.fngetStatus());

    this.fngetStatus();

    this.fngetArea();

    // this.intervalId = setInterval(this.fngetStatus, 10000);
  }

  fnCheckPrivilage() {
    this.isadd = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE');
    this.isedit = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER');
    this.isdelete = this.loginService.fnCheckUserRoleAction(this.formname, 'DELETE');
    this.isview = this.loginService.fnCheckUserRoleAction(this.formname, 'VIEW');
    this.isprint = this.loginService.fnCheckUserRoleAction(this.formname, 'PRINT');
    this.isexport = this.loginService.fnCheckUserRoleAction(this.formname, 'EXPORT');

    this.isorderplaced = this.loginService.fnCheckUserRoleAction("OrderPlaced", 'ALTER');
    this.isorderpacking = this.loginService.fnCheckUserRoleAction("Shipment", 'CREATE');
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }


  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {

    this.subscription && this.subscription.unsubscribe();
    clearInterval(this.intervalId);
  }



  //Grid


  //Event  
  fnActionEditClick(data) {
    this.fnEditStatus(data.orderid);
  }

  fnActionExportClick() {
    const modalRef = this.productFindService.open(ExportgridComponent, { size: <any>'xl', backdrop: 'static' });
    modalRef.componentInstance.doctype = this.formname;
    modalRef.componentInstance.columnDefs = this.columnDefs;
    modalRef.componentInstance.contentColl = this.orderColl;
    modalRef.componentInstance.fnSetTable();
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {


    });
  }



  fngetOrderListView() {
    if (this.showProgress) {
      return;
    }

    this.fnShowProgress();
    var data = {
      'areaid': this.areaid,
      'statusid': this.statusid,
      filterby: this.filterby,
      fromdate: this.fnFormatDatetime(this.fromdate),
      todate: this.fnFormatDatetime(this.todate),
      modifiedby: this.loginService.fnGetModUserId(),

    }
    this.appService.getOrderListView(data)
      .subscribe(
        (res) => {
          this.fnHideProgress();
          if (res.json().status == 200) {
            this.orderColl = res.json().result;
            this.fnSetDataTable();
            this.fnReloadStatus();
            this.fnCurrentRouteChange();
          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  fnCurrentRouteChange() {

    const queryParams: Params = {
      'filterstatus': this.filterby,
    };

    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: queryParams,

      });
  }


  fngetArea() {
    var data = {
      'areaid': 0,
    }
    this.appService.getArea(data)
      .subscribe(
        (res) => {
          if (res.json().status == 200) {
            this.areaColl = res.json().result;
          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }



  fngetStatus() {
    var data = {
      'statusid': 0,
    }
    this.appService.getOrderStatus(data)
      .subscribe(
        (res) => {
          if (res.json().status == 200) {
            // this.tempstatusColl=res.json().result;   

            // this.statusColl=[];

            // for(let wbo of this.tempstatusColl){
            //   if(wbo.statustype==='PLACED' && this.isorderplaced)
            //   {
            //     this.statusColl.push(wbo)
            //   }
            //   if(wbo.statustype==='CONFIRMED' && this.isorderpacking)
            //   {
            //     this.statusColl.push(wbo)
            //   }

            //   if(wbo.statustype==='PACKED' && this.isorderpacking)
            //   {
            //     this.statusColl.push(wbo)
            //   }


            // }

            let result = res.json().result[0];
            this.orderplacedcount = result.placedcount;
            this.confirmcount = result.confirmcount;
            this.topackcount = result.topackcount;
            this.assigncount = result.assigncount;
            this.readytopickcount = result.readytopickcount;
            this.pickedcount = result.pickedcount;
            this.outfordelivercount = result.outfordelivercount;
            this.deliverycount = result.deliveredcount;
            this.receivedcount = result.receivedcount;
            this.cancelledcount = result.cancelledcount;
            this.allcount = result.allcount;



            // this.totalordercount=0;
            // for(let wbo of this.statusColl){
            //   this.totalordercount=wbo.totalcount;
            // }

            // if(this.statusColl.length ){
            //   this.statusid=this.statusColl[0].statusid;
            // }

            this.fngetOrderListView();

          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fnReloadStatus() {
    var data = {
      'statusid': 0,
    }
    this.appService.getOrderStatus(data)
      .subscribe(
        (res) => {
          if (res.json().status == 200) {
            // this.tempstatusColl=res.json().result;   

            // this.statusColl=[];

            // for(let wbo of this.tempstatusColl){
            //   if(wbo.statustype==='PLACED' && this.isorderplaced)
            //   {
            //     this.statusColl.push(wbo)
            //   }
            //   if(wbo.statustype==='CONFIRMED' && this.isorderpacking)
            //   {
            //     this.statusColl.push(wbo)
            //   }

            //   if(wbo.statustype==='PACKED' && this.isorderpacking)
            //   {
            //     this.statusColl.push(wbo)
            //   }


            // }  

            // this.totalordercount=0;
            // for(let wbo of this.statusColl){
            //   this.totalordercount=wbo.totalcount;
            // }

            let result = res.json().result[0];
            this.orderplacedcount = result.placedcount;
            this.topackcount = result.topackcount;
            this.assigncount = result.assigncount;
            this.readytopickcount = result.readytopickcount;
            this.pickedcount = result.pickedcount;
            this.deliverycount = result.deliveredcount;
            this.receivedcount = result.receivedcount;
            this.cancelledcount = result.cancelledcount;
            this.allcount = result.allcount;


          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fnChangeStatus(stBO) {

    this.statusid = stBO.statusid;
    this.fngetOrderListView();
  }

  fnGetStatusTabActiveCalzz(stBO) {
    var _result = '';

    if (stBO) {
      if (stBO.statusid === this.statusid) {
        _result = 'activetab'
      }
    }



    return _result;
  }

  fnEditStatus(deptid) {
    try {
      if (!this.isview) {
        return
      }
      let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': deptid,
          'sid': this.statusid,
          'filterstatus': this.filterby

        }
      }

      this.router.navigate(['/AddOrders'], navigationExtras);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  private fnSetDataTable() {

  }

  fnGeoLocationDeliveryBoy() {


    const modalRef = this.productFindService.open(DeliveryboyaddressmapComponent, { size: <any>'xl' });
    modalRef.componentInstance.doctype = "COMBO PRODUCT";
    modalRef.componentInstance.deliveryboymaptype = "ALL";
    modalRef.componentInstance.fngetDeliveryBoy();
    modalRef.componentInstance.fnServiceChanges();
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {


    });


  }



  fnResetGrid(data) {
    this.searchtxt = data;
    this.gridOptions.pageindex = 1
  }




  fnActionExportProductClick() {
    if (this.showProgress) {
      return;
    }

    this.fnShowProgress();
    var data = {
      'statusid': this.statusid,
      filterby: this.filterby,
      fromdate: this.fnFormatDatetime(this.fromdate),
      todate: this.fnFormatDatetime(this.todate),
      modifiedby: this.loginService.fnGetModUserId(),

    }
    this.appService.getOrderProductListView(data)
      .subscribe(
        (res) => {
          this.fnHideProgress();
          if (res.json().status == 200) {
            var productColl = res.json().result;

            var scolumnDefs = [
              { headerName: 'Order', field: 'orderno' },
              { headerName: 'Order Date', field: 'orderdate', type: 'datetime', colcls: "tbtx" },
              { headerName: 'Customer Name', field: 'customername' },
              { headerName: 'Mobile', field: 'mobile' },
              { headerName: 'Sku', field: 'sku', colcls: "tbtx" },
              { headerName: 'Product', field: 'productname' },
              { headerName: 'Qty', field: 'qty', type: 'decimal' },
            ]
            const modalRef = this.productFindService.open(ExportgridComponent, { size: <any>'xl', backdrop: 'static' });
            modalRef.componentInstance.doctype = this.filterby + "-Product List";
            modalRef.componentInstance.columnDefs = scolumnDefs;
            modalRef.componentInstance.contentColl = productColl;
            modalRef.componentInstance.fnSetTable();
            modalRef.componentInstance.emitData.subscribe((receivedEntry) => {


            });

          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })




  }


  fnFormatDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        return this.datePipe.transform(dt, "yyyy-MM-dd")
      }

    } else {
      return null
    }

  }

  fnConvertDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {


        var dateParts = dt.split("-");

        var sdate = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2] + 1);

        sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());



        return sdate;
      }

    } else {
      return null
    }

  }

  fnMomentDateFormatter(params) {
    if (params.value) {
      return moment(params.value).format('DD-MM-YYYY');//hh:mm a  
    }
    else {
      return '';
    }
  }

  fnMomentDateTimeFormatter(params) {
    if (params.value) {
      return moment(params.value).format('DD-MM-YYYY hh:mm a');//  
    }
    else {
      return '';
    }

  }


  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }


}
